import Vue from 'vue'
import Vuex from 'vuex'
import { $api } from 'bh-mod'
import settings from './settings'
import {setProp, deleteProp,arrToObj} from 'bh-mod'
// setProp(state,['units',data.id],data)
Vue.use(Vuex)

    export default {
    state: {
        chooseColor:{
            show:false,
            data:{}
        },
        siteURL:window.location.href.includes('localhost')? 'http://localhost:3000/eblast' : 'https://services.bildhive.com/eblast',
        loadingPage:false,
        newStoreTime:Date.now(),
        getStarted: false,
        allSettings:{},
        groups:{},
        features:{},
        bricks:{},
		previewModal:{
			show:false,
			data:{}
		},
        dom:{
            w:document.body.clientWidth,
            h:document.body.clientHeight
        },
        addContent:{
            show:false,
            page:{},
            isNew:false
        },

    },
    mutations: {
		REMOVE_FEATURES(state, data){
			console.log('FEATURES', state.features)
			let features = JSON.parse(JSON.stringify(state.features))
			data.forEach(x => {
				delete features[x]
			})
			state.features = features;
		},
		REMOVE_BRICKS(state, data){
			console.log('BRICKS', state.bricks)
			let bricks = JSON.parse(JSON.stringify(state.bricks))
			data.forEach(x => {
				delete bricks[x]
			})
			state.bricks = bricks;
		},
		SHOW_PREVIEW(state, data){
			state.previewModal = {
				show:true,
				data
			}
		},
		CLOSE_PREVIEW(state){
			state.previewModal = {
				show:false,
				data:{}
			}
		},
        VIEWPORT: (state,w) => state.dom = w,
        SHOW_ADD_CONTENT: (state,{page,isNew}) => state.addContent={show: true,page,isNew },
        CLOSE_ADD_CONTENT: state => state.addContent={show: false,page:{},isNew:false },
        LOAD_PAGE: (state,status = !state.loadingPage) => state.loadingPage = status,
        SET_PROP: (state,{where,what,del = false,update = false}) => {
            if (del) return deleteProp(state,where)
            state.newStoreTime = Date.now()
            setProp(state,where,what)
        },
        CLOSE_DRAWERS: state => {

            state.featuresDrawer = {
                show:false,
                type:'',
                id:''
            }
            state.broadcastModal = {show:false,data:{}}

        },
        CLOSE_MODALS: (state) => {

            state.statusModal = {type:'', show:false}
            state.formModal = {show:false,type:''}
            state.chooseColor = {show:false,type:'',color:''}

        },
        SHOW_ADD_TEMPLATE: (state, data)=> {
            state.templateDrawer = {
                show:true,
                type:'add',
                id:''
            }
        },
        SHOW_EDIT_TEMPLATE: (state, template)=> {
            state.drafts[template.id] = template
            state.templateDrawer = {
                show:true,
                type:'edit',
                id:template.id
            }
        },
        SET_APPDATA(state, data) {
			console.log('SETTING APPDATA', data)
            if (!data) data = {}
            let {featureGroups = [],features = [], bricks = []} = data
            features = features.filter(x => x.group && x.group.id)
            features = features.map(x => {
                return x.group = x.group.id,x
            })
            features = features.map(f => {
                if (!f.order) f.order = 0
                return f
            })
            bricks = bricks.map(f => {
                if (!f.order) f.order = 0
                return f
            })

            state.groups = arrToObj(featureGroups)
            state.features = arrToObj(features)
            state.bricks = arrToObj(bricks)

        },
        GET_STARTED: state => {
            state.getStarted = true
        },
		SET_SETTINGS(state, data) {
            if (data.userApp == null) {
                data.userApp = {
                    options:{
                        seenIntro:false,
                    }
                }
            }
            state.allSettings = data
        },
    },
    actions: {
        UPDATE_SYNC: ({commit,state}, {id,type,data}) => {
            return new Promise(resolve => {
                setTimeout(() => {

                    commit('SET_PROP',{where:[type,id], what:data})
                    resolve()

                }, 2000);
            })
        },

        SET_APPDATA: ({commit},data) => commit('SET_APPDATA',data),
        CLOSE_DRAWER: ({commit},{type,data}) => {
            if (type !== 'click') commit('SET_PROP', {where:['templates',data.id], what:data, del:type === 'delete'})
            commit('CLOSE_DRAWERS')
        },
        MESSAGE: ({state},data) => {
            document.querySelector('iframe').contentWindow.postMessage(data, state.siteURL)
        }

    },
    getters:{
        emailApi: (state, getters, rootState) => `/email-sections/${rootState.instance.id}`
    }
}
