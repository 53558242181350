import Vue from "vue";
import Router from "vue-router";
import Splash from "@/layouts/Splash";
import AuthLayout from 'bh-mod/layouts/Auth'
import store from "@/store";
import { VueAppMiddleware } from "bh-mod";
import AppLayout from "bh-mod/layouts/App";

Vue.use(Router);

const router = new Router({
	base: process.env.BASE_URL,
	mode: "history",
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "/",
			redirect: "/v3",
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true },
			children: [
				// Dashboards
				{
					path: "/",
					meta: {
						title: "Exterior Color Packages",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/app"),
				},
				{
					path: "/addbrick",
					meta: {
						title: "Exterior Color Packages",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/app/newBrick"),
				},
				{
					path: "/editbrick/:id",
					meta: {
						title: "Exterior Color Packages",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/app/newBrick"),
				},
				{
					path: "/features",
					meta: {
						title: "Features & Finishes",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/app/features"),
				},
				{
					path: "/addfeatures",
					meta: {
						title: "Features & Finishes",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/app/newFeatures"),
				},
				{
					path: "/editfeatures/:id",
					meta: {
						title: "Features & Finishes",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/app/newFeatures"),
				},
				{
					path: "/categorysettings",
					meta: {
						title: "Category Settings",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/app/categories"),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			path: "/setup",
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [
				{
					path: "/setup",
					meta: {
						title: "Setup Features & Finishes",
						splashScreen: true,
					},
					component: () => import("./views/setup"),
				},
			],
		},
		{
			path: '/404',
			component: Splash,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		{
			path: '/unauthorized',
			component: AuthLayout,
			children: [
				{
					path: '/unauthorized',
					meta: {
						title: 'Unauthorized',
						unauthorized: true
					},
					component: () => import('bh-mod/components/common/unauthorized.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
});

let hiveRequested = false
router.beforeEach(async (to, from, next) => {
	let dataRequired = to.matched.some((record) => record.meta.dataRequired);
	let splashScreen = to.matched.some((record) => record.meta.splashScreen);
	let unauthorized = to.matched.some(record => record.meta.unauthorized)
	let error = false

	if (!hiveRequested) {
		let response = await VueAppMiddleware(to, store, 'features')
		error = response.parsed
		hiveRequested = true;
	}

	if (error) {
		if (error === 'User does not have required permission!') {
			return next('/unauthorized')
		}
		return console.error('ERROR', error)
	} else {
		if (unauthorized) {
			return next();
		}
		if (splashScreen && (Object.keys(store.state.features.groups).length || Object.keys(store.state.features.features).length || Object.keys(store.state.features.bricks).length || store.state.features.allSettings.userApp.options.seenIntro)) {
			return next('/')
		}
		if (dataRequired && !Object.keys(store.state.features.groups).length && !Object.keys(store.state.features.features).length && !Object.keys(store.state.features.bricks).length && !store.state.features.getStarted && !store.state.features.allSettings.userApp.options.seenIntro) {
			return next('/setup')
		}

		next()
	}
	next();
});

export default router;
