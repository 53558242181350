<template>
    <div v-if="true">
        <div
            :class="$style.subbar"
            v-if="instance"
            style="background-color: #f7f5f9"
        >
            <div class="dF aC mb-0">
                <div
                    :class="$style.breadcrumbs"
                    style="align-items: center; display: block !important"
                >
                    <a-breadcrumb>
                        <a-breadcrumb-item>{{
                            instance.name
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item>{{
                            selectedAppInitial
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item style="color: var(--orange)">{{
                            $route.path.includes("features")
                                ? "Features & Finishes"
                                : $route.path == "/" ||
                                  $route.path.includes("brick")
                                ? "Exterior Color Packages"
                                : "Category Settings"
                        }}</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>

                <div :class="$style.amount" class="ml-auto d-none d-sm-flex">
                    <a-button
                        v-if="$route.path == '/features'"
                        @click="$router.push('/categorysettings')"
                        size="large"
                        class="mr-4 secondary-button"
                    >
                        CATEGORY SETTINGS
                    </a-button>
                    <a-button
                        v-if="$route.path == '/features'"
                        @click="$router.push('/addfeatures')"
                        type="primary"
                        icon="plus"
                        size="large"
                    >
                        ADD NEW FEATURES & FINISHES
                    </a-button>
                    <a-button
                        v-if="$route.path == '/'"
                        @click="$router.push('/addbrick')"
                        type="primary"
                        icon="plus"
                        size="large"
                    >
                        ADD NEW PACKAGE
                    </a-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const search = {
    leadScore: {
        type: "_gte",
        value: 1,
    },
    firstName: {
        type: "_contains",
        value: "",
    },
    lastName: {
        type: "_contains",
        value: "",
    },
    email: {
        type: "_contains",
        value: "",
    },
    tagI: {
        type: "any",
        value: [],
    },
    tagE: {
        type: "any",
        value: [],
    },
};
export default {
    data() {
        return {
            loadSearch: false,
            search: JSON.parse(JSON.stringify(search)),
        };
    },
    watch: {
        showSearch(val) {
            if (val) {
                let OBJ = JSON.parse(JSON.stringify(search));
                let storedSearch = JSON.parse(
                    JSON.stringify(this.$store.state.features.searchOBJ)
                );
                if (
                    storedSearch.leadScore &&
                    storedSearch.leadScore.hasOwnProperty("value")
                ) {
                    storedSearch.leadScore.value = parseInt(
                        storedSearch.leadScore.value
                    );
                }

                Object.entries(storedSearch).forEach(([key, value]) => {
                    OBJ[key] = value;
                });
                this.search = OBJ;
            } else {
                this.loadSearch = false;
                this.search = JSON.parse(JSON.stringify(search));
            }
        },
    },
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "FC"
            );
        },
        showSearch() {
            return this.$store.state.features.showSearch;
        },
        tags() {
            return this.$store.state.features.tags;
        },
        instance() {
            return this.$store.state.instance;
        },
    },
    methods: {
        searchContacts() {
            this.loadSearch = true;
            this.$store.commit("SET_SEARCH", this.search);
            this.$store.commit("SET_NEW_SEARCH_TIME");
            this.$store.commit("TOGGLE_SEARCH");
        },
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
.secondary-button.ant-btn.ant-btn-lg {
    background-color: #f7f5f9;
    color: var(--orange);
    border-color: var(--orange);
    &:hover {
        background-color: var(--orange);
        color: #fff;
    }
}
.ant-form-horizontal.search-horizontal {
    label {
    }
    .ant-form-item-control-wrapper {
        flex: 1;
    }
    .ant-form-item {
        display: flex;
    }
    .ant-form-item-label {
        min-width: 100px;
    }
}
</style>
