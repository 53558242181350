<template>
  <div id="app">
    <MediaSelector />
    <localization></localization>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'

export default {
  name: 'app',
  components: { Localization,MediaSelector },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  methods:{

  }
}
</script>
<style>
  #app{
    min-height: 100vh;
  }
  .ant-drawer-body{
    padding:0;
    margin:0;
  }
  iframe{
    border:0;
  }
  .ant-drawer-header-no-title [aria-label="Close"]{
    background:white;
  }
</style>
