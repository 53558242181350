<template>
    <div style="height: 100%">
        <LeftNavBar
            :title="selectedAppInitial"
            :items="navItems"
            @opened="navOpened"
            :selectedValue="
                $route.path == '/features'
                    ? 'features'
                    : $route.path == '/'
                    ? 'brick'
                    : ''
            "
        />
    </div>
</template>

<script>
import Expander from "bh-mod/components/common/Expander";
import vueCustomScrollbar from "vue-custom-scrollbar";
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
    components: { Expander, vueCustomScrollbar, LeftNavBar },
    name: "menu-left",
    data() {
        return {
            openID: "",
        };
    },
    computed: {
		selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "FC"
            );
        },
        navItems() {
            let items = [
                {
                    label: "Exterior Color Packages",
                    value: "brick",
					iconUrl: require("@/assets/sideIcons/brick.svg"),
                },
                {
                    label: "Features & Finishes",
                    value: "features",
					iconUrl: require("@/assets/sideIcons/features.svg"),
                },
            ];

            return items;
        },
        lots() {
            return this.$store.state.features.lots;
        },
        folders() {
            return this.$store.getters.folders;
        },
        files() {
            return this.$store.getters.allFiles;
        },
    },
    methods: {
        navOpened(e) {
            if (e.value === "brick") {
                if (this.$route.path !== "/") {
                    this.$router.push("/");
                }
            }
            if (e.value === "features") {
                if (this.$route.path !== "/features") {
                    this.$router.push("/features");
                }
            }
        },
        editLot(lot) {
            console.log("EDITING LOT", lot);
            this.$store.commit("SHOW_EDIT_LOT", lot);
        },
        handleClick(e) {
            this.$store.commit("changeFolder", e.key);
        },
    },
};
</script>
